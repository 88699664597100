import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { Navigation } from './navigation/navigation';
import { MainContent } from './mainContent/mainContent';
import { DealContent } from './dealContent/dealContent';

const Content: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Router>
        <MainContent path={'/'} />
        <DealContent path={'deal/:dealId'} />
      </Router>
    </>
  );
};

export const Application: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Router>
        <Navigation path={'/'} >
          <Content path={'/*'} />
        </Navigation>
      </Router>
    </>
  );
};
