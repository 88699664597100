import gql from 'graphql-tag';

export const GET_USER_NAVIGATION = gql`
  {
    currentUser {
      id
      firstName
      participating {
        id
        deal {
          id
          codename
        }
      }
    }
  }
`;

export interface NavigationUser {
  currentUser: {
    id: string;
    firstName: string;
    participating: {
      id: string;
      deal: {
        id: string;
        codename: string;
      };
    }[];
  };
}
