import React from 'react';
import { ICellRenderer, Cell } from '@blueprintjs/table';

export abstract class DataRenderer<T extends any> {
  abstract getData(row: number): T | undefined;
  cellRenderer(): ICellRenderer {
    return (rowIndex: number) => {
      return <Cell>{this.getData(rowIndex)}</Cell>;
    };
  }
}
