import gql from 'graphql-tag';

export const JOIN_DEAL_NAVIGATION = gql`
  mutation JoinDeal($dealId: ID!) {
    joinDeal(dealId: $dealId) {
      id
    }
  }
`;

export interface JoinDealVariables {
  dealId: string;
}

export interface JoinDealResults {
  joinDeal: {
    id: string;
  };
}

export const CREATE_DEAL_NAVIGATION = gql`
  mutation CreateDeal(
    $codename: String!
    $amount: Int!
    $spread: Int!
    $endDate: String!
    $description: String!
    $dataroom: String!
  ) {
    createDeal(
      codename: $codename
      amount: $amount
      spread: $spread
      endDate: $endDate
      description: $description
      dataroom: $dataroom
    ) {
      id
    }
  }
`;

export interface CreateDealVariables {
  codename: string;
  amount: number;
  spread: number;
  endDate: string;
  description: string;
  dataroom: string;
}

export interface CreateDealResults {
  createDeal: {
    id: string;
  };
}
