import React from 'react';

interface ParticipantSubheaderProps {
  text: string;
  colSpan: number;
  padTop: boolean;
}

export const ParticipantSubheader: React.FC<ParticipantSubheaderProps> = (
  props: ParticipantSubheaderProps
) => {
  return (
    <tr>
      <td
        colSpan={props.colSpan}
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: props.padTop ? undefined : 0,
        }}
      >
        <div className={'text-gray-2 bg-light-gray-4 font-medium p-2'}>
          {props.text}
        </div>
      </td>
    </tr>
  );
};
