import React from 'react';
import {
  Navbar as BlueprintNavbar,
  Alignment,
  Button,
  Popover,
  Menu,
  Classes,
  Icon,
} from '@blueprintjs/core';
import logo from '../../../assets/linelogo.svg';
import { IconNames } from '@blueprintjs/icons';
import { Action } from '../../../types/action';

interface NavbarProps {
  className?: string;
  loading?: boolean;
  name: string;
  logoCallback: () => void;
  actions?: Action[];
}

export const Navbar: React.FunctionComponent<NavbarProps> = (
  props: NavbarProps
) => {
  return (
    <BlueprintNavbar className={props.className}>
      <BlueprintNavbar.Group align={Alignment.LEFT}>
        <img
          src={logo}
          alt="Arithos"
          className={'h-full p-3 cursor-pointer'}
          onClick={props.logoCallback}
        />
      </BlueprintNavbar.Group>
      <BlueprintNavbar.Group>
        <Icon icon={'build'} intent={'warning'} />
        <div className={'inline-block text-orange-3 font-bold mx-4'}>DEMO ENVIRONMENT</div>
      </BlueprintNavbar.Group>
      <BlueprintNavbar.Group align={Alignment.RIGHT}>
        <Popover
          content={
            <Menu>
              {props.actions &&
                props.actions.map((value: Action, index: number) => {
                  return (
                    <Menu.Item
                      key={index}
                      text={value.name}
                      icon={value.icon}
                      onClick={value.callback}
                      intent={value.intent}
                      disabled={value.disabled}
                    />
                  );
                })}
            </Menu>
          }
          position={'bottom-right'}
        >
          <Button
            icon={IconNames.USER}
            rightIcon={IconNames.CHEVRON_DOWN}
            minimal={true}
          >
            <span className={props.loading ? Classes.SKELETON : ''}>
              {props.name}
            </span>
          </Button>
        </Popover>
      </BlueprintNavbar.Group>
    </BlueprintNavbar>
  );
};
