import React from 'react';
import { Chart } from '../../molecules/chart/chart';
import { TitledBlock } from '../../molecules/titledBlock/titledBlock';
import { IconNames } from '@blueprintjs/icons';

interface ChartBlockProps {
  yAxisName: string;
  xAxisName: string;
  data: [number, number][];
}

export const ChartBlock: React.FC<ChartBlockProps> = (
  props: ChartBlockProps
) => {
  return (
    <TitledBlock
      icon={IconNames.STEP_CHART}
      iconClassName={'bg-blue-1'}
      title={'Demand Curve'}
    >
      <Chart
        height={500}
        yAxisName={props.yAxisName}
        xAxisName={props.xAxisName}
        data={props.data}
      />
    </TitledBlock>
  );
};
