import React from 'react';
import { TitledBlock } from '../../molecules/titledBlock/titledBlock';
import { IconNames } from '@blueprintjs/icons';
import { HTMLTable, Spinner, NonIdealState } from '@blueprintjs/core';
import { ActiveDeal } from './activeDeal';
import { ActiveDealRow } from './activeDealsRow';

interface ActiveDealsBlockProps {
  loading?: boolean;
  deals: ActiveDeal[];
  className?: string;
}

export const ActiveDealsBlock: React.FC<ActiveDealsBlockProps> = (
  props: ActiveDealsBlockProps
) => {
  return (
    <div className={props.className}>
      <TitledBlock
        icon={IconNames.SEARCH_AROUND}
        iconClassName={'bg-blue-1'}
        title={'Active Deals'}
        className={'w-full'}
      >
        <div className={'flex justify-center'}>
          {(() => {
            if (props.loading) {
              return <Spinner size={Spinner.SIZE_LARGE} className={'my-16'} />;
            } else if (props.deals.length === 0) {
              return (
                <NonIdealState
                  icon={IconNames.SEARCH}
                  description={'No active deals found.'}
                  className={'my-12'}
                />
              );
            } else {
              return (
                <HTMLTable interactive={true} className={'flex-1'}>
                  <thead>
                    <tr>
                      <th>Deal</th>
                      <th>Amount</th>
                      <th>Rate</th>
                      <th>End Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.deals.map((value: ActiveDeal, index: number) => (
                      <ActiveDealRow deal={value} key={index} />
                    ))}
                  </tbody>
                </HTMLTable>
              );
            }
          })()}
        </div>
      </TitledBlock>
    </div>
  );
};
