import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { MainTemplate } from '../../../template/mainTemplate/mainTemplate';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_AND_DEALS, ContentUser } from './query';
import { ApplicationToaster } from '../toaster';
import { ActiveDeal } from '../../../organism/activeDealsBlock/activeDeal';
import moment from 'moment';

export const MainContent: React.FC<RouteComponentProps> = ({ navigate }) => {
  const { loading, error, data } = useQuery<ContentUser>(GET_USER_AND_DEALS);

  if (error) {
    ApplicationToaster.show({ message: error.message, intent: 'danger' });
  }

  const navigateToDeal = (id: string) => {
    return () => {
      if (navigate) navigate('/deal/' + id);
    };
  };

  // TODO make this work with graphql
  return (
    <MainTemplate
      loading={loading || error !== undefined}
      activeDeals={
        data !== undefined
          ? data.currentUser.participating.map(
              (value): ActiveDeal => {
                const deal = value.deal;
                return {
                  codename: deal.codename,
                  description: deal.description,
                  sponsor: deal.sponsor.user.firm,
                  amount: deal.amount,
                  rate: deal.spread,
                  endDate: moment(deal.endDate).format('L LT'),
                  callback: navigateToDeal(deal.id),
                };
              }
            )
          : []
      }
    />
  );
};
