import React, { useState } from 'react';
import { BaseTemplate } from '../../../template/baseTemplate/baseTemplate';
import { RouteComponentProps, NavigateFn } from '@reach/router';
import { IconNames } from '@blueprintjs/icons';

import { CreateDealDialog } from '../../../organism/createDealDialog/createDealDialog';
import { handleLogout } from './logout';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { NavigationUser, GET_USER_NAVIGATION } from './query';
import { ApplicationToaster } from '../toaster';
import { DealItem } from '../../../organism/sidebar/sidebar';
import {
  CreateDealResults,
  CreateDealVariables,
  CREATE_DEAL_NAVIGATION,
  JoinDealVariables,
  JOIN_DEAL_NAVIGATION,
  JoinDealResults,
} from './mutation';
import moment from 'moment';
import { JoinDealDialog } from '../../../organism/joinDealDialog/joinDealDialog';
import { RESET_DEMO } from './demo';

interface NavigationProps {
  children: React.ReactNode;
}

export const Navigation: React.FC<NavigationProps & RouteComponentProps> = (
  props: NavigationProps & { navigate?: NavigateFn }
) => {
  const [isCreatingAuction, setIsCreatingAuction] = useState<boolean>(false);
  const [isJoiningDeal, setIsJoiningDeal] = useState<boolean>(false);
  const { loading, error, data, refetch } = useQuery<NavigationUser>(
    GET_USER_NAVIGATION
  );
  const [createDeal] = useMutation<CreateDealResults, CreateDealVariables>(
    CREATE_DEAL_NAVIGATION
  );
  const [joinDeal] = useMutation<JoinDealResults, JoinDealVariables>(
    JOIN_DEAL_NAVIGATION
  );
  const [resetDemo] = useMutation(RESET_DEMO);

  if (error) {
    ApplicationToaster.show({ intent: 'danger', message: error.message });
  }

  const navigateToDeal = (id: string) => {
    return () => {
      if (props.navigate) props.navigate('/deal/' + id);
    };
  };

  return (
    <>
      <CreateDealDialog
        isOpen={isCreatingAuction}
        onClose={() => {
          setIsCreatingAuction(false);
        }}
        onSubmit={async values => {
          try {
            const createdDeal = await createDeal({
              variables: {
                codename: values.codename,
                description: values.description,
                amount: values.amount,
                spread: values.rate,
                dataroom: values.dataroom,
                endDate: values.endDate
                  ? values.endDate!.toISOString()
                  : moment().toISOString(),
              },
            });
            if (createdDeal.errors) {
              createdDeal.errors.forEach(value => {
                ApplicationToaster.show({
                  message: value.message,
                  intent: 'danger',
                });
              });
            }
            if (createdDeal.data) {
              navigateToDeal(createdDeal.data.createDeal.id)();
              refetch();
            }
          } catch (e) {
            ApplicationToaster.show({
              message: String(e),
              intent: 'danger',
            });
          }
          setIsCreatingAuction(false);
        }}
      />
      <JoinDealDialog
        isOpen={isJoiningDeal}
        onClose={() => {
          setIsJoiningDeal(false);
        }}
        onSubmit={async values => {
          try {
            const result = await joinDeal({
              variables: { dealId: values.dealId },
            });
            if (result.errors) {
              result.errors.forEach(value => {
                ApplicationToaster.show({
                  message: value.message,
                  intent: 'danger',
                });
              });
            }
            if (result.data) {
              navigateToDeal(values.dealId)();
              refetch();
            }
          } catch (e) {
            ApplicationToaster.show({
              message: String(e),
              intent: 'danger',
            });
          }
          setIsJoiningDeal(false);
        }}
      />
      <BaseTemplate
        loading={loading || error !== undefined}
        logoCallback={() => {
          if (props.navigate) props.navigate('/');
        }}
        navbarActions={[
          {
            name: 'Switch user: Andy',
            icon: IconNames.SWAP_HORIZONTAL,
            callback: () => {
              localStorage.setItem('user', 'andy');
              if (props.navigate)
                props.navigate('/').then(() => window.location.reload());
            },
          },
          {
            name: 'Switch user: Sam',
            icon: IconNames.SWAP_HORIZONTAL,
            callback: () => {
              localStorage.setItem('user', 'sam');
              if (props.navigate)
                props.navigate('/').then(() => window.location.reload());
            },
          },
          {
            name: 'Switch user: Jim',
            icon: IconNames.SWAP_HORIZONTAL,
            callback: () => {
              localStorage.setItem('user', 'jim');
              if (props.navigate)
                props.navigate('/').then(() => window.location.reload());
            },
          },
          {
            name: 'Reset Demo',
            icon: IconNames.RESET,
            callback: async () => {
              try {
                await resetDemo();
                ApplicationToaster.show({
                  message:
                    'The demo has been reset. Close this message to refresh the page.',
                  intent: 'primary',
                  timeout: 5000,
                  onDismiss: () => {
                    if (props.navigate)
                      props.navigate('/').then(() => window.location.reload());
                  },
                });
              } catch (e) {
                ApplicationToaster.show({
                  message: String(e),
                  intent: 'danger',
                });
              }
            },
            intent: 'danger',
          },
          {
            name: 'Settings',
            icon: IconNames.SETTINGS,
            callback: () => {},
            disabled: true,
          },
          {
            name: 'Log Out',
            icon: IconNames.LOG_OUT,
            callback: handleLogout(props.navigate),
            intent: 'danger',
            disabled: true,
          },
        ]}
        user={data ? data.currentUser.firstName : 'Placeholder'}
        sidebarActions={[
          {
            name: 'Search',
            icon: IconNames.SEARCH,
            callback: () => {},
            disabled: true,
          },
          {
            name: 'Create Deal',
            icon: IconNames.ADD,
            callback: () => {
              setIsCreatingAuction(true);
            },
          },
          {
            name: 'Join Deal',
            icon: IconNames.SEND_TO_GRAPH,
            callback: () => {
              setIsJoiningDeal(true);
            },
          },
        ]}
        // TODO make this work with graphql
        deals={
          data
            ? data.currentUser.participating.map(
                (participating): DealItem => {
                  return {
                    name: participating.deal.codename,
                    callback: navigateToDeal(participating.deal.id),
                  };
                }
              )
            : []
        }
      >
        {props.children}
      </BaseTemplate>
    </>
  );
};
