import React from 'react';
import { Participant } from './participant';
import { Icon, Colors } from '@blueprintjs/core';

interface ParticipantTrackerRowProps {
  participant: Participant;
}

// No Tailwin b/c of CSS specificity
const alignMiddle = { verticalAlign: 'middle' };

export const ParticipantTrackerRow: React.FC<ParticipantTrackerRowProps> = (
  props: ParticipantTrackerRowProps
) => {
  return (
    <tr>
      <td style={alignMiddle} className={'mr-4'}>
        <div>
          <div>{props.participant.firm}</div>
        </div>
      </td>
      <td style={alignMiddle}>
        <div>
          {props.participant.signedNDA ? props.participant.signedNDA : '-'}
        </div>
      </td>
      <td style={alignMiddle}>
        <div>
          {props.participant.signedNDA ? props.participant.bidsSubmitted : '-'}
        </div>
      </td>
      <td>
        <div className={'mb-2 font-medium'}>{props.participant.contact}</div>
        <div>
          <div className={'mb-1'}>
            <Icon icon={'envelope'} color={Colors.GRAY2} />
            <span className={'mx-2 my-0'}>{props.participant.email}</span>
          </div>
        </div>
        <div>
          <Icon icon={'phone'} color={Colors.GRAY2} />
          <span className={'mx-2 my-0'}>{props.participant.phone}</span>
        </div>
      </td>
    </tr>
  );
};
