import React from 'react';

import { Column, Table } from '@blueprintjs/table';
import { TitledBlock } from '../titledBlock/titledBlock';
import { IconNames, IconName } from '@blueprintjs/icons';
import { DataRenderer } from './dataRenderer/dataRenderer';

import { Stringifier } from 'csv-stringify';
import FileSaver from 'file-saver';
import { Action } from '../../../types/action';

export interface ColumnData {
  name: string;
  dataRenderer: DataRenderer<any>;
  width?: number;
}

const ConstructTableDownload = (
  numRows: number,
  data: ColumnData[],
  onError: (err: Error) => void
): Blob => {
  const csvData: string[] = [];
  const stringifier = new Stringifier({});
  stringifier.on('readable', () => {
    let row: any;
    while ((row = stringifier.read())) {
      csvData.push(String(row));
    }
  });
  stringifier.on('error', onError);

  stringifier.write(data.map((value: ColumnData) => value.name));
  for (let row = 0; row < numRows; row++) {
    stringifier.write(
      data.map((value: ColumnData) => value.dataRenderer.getData(row))
    );
  }
  stringifier.end();

  return new Blob(csvData, { type: 'text/csv' });
};

interface DataTableProps {
  title: string;
  numRows: number;
  columns: ColumnData[];
  className?: string;
  icon?: IconName;
  iconClassName?: string;
  actions?: Action[];
}

export const DataTable: React.FunctionComponent<DataTableProps> = (
  props: DataTableProps
) => {
  return (
    <TitledBlock
      title={props.title}
      icon={props.icon}
      iconClassName={props.iconClassName}
      className={props.className}
      actions={(() => {
        const actions: Action[] = [
          {
            name: 'Download as CSV',
            icon: IconNames.DOWNLOAD,
            callback: () => {
              const blob: Blob = ConstructTableDownload(
                props.numRows,
                props.columns,
                () => {}
              );
              FileSaver.saveAs(blob, props.title + '.csv');
            },
          },
        ];

        if (props.actions) actions.push(...props.actions);
        return actions;
      })()}
    >
      <Table
        numRows={props.numRows}
        enableRowHeader={false}
        getCellClipboardData={(row: number, col: number) => {
          return props.columns[col].dataRenderer.getData(row);
        }}
        columnWidths={props.columns.map((value: ColumnData) => value.width)}
      >
        {props.columns.map((value: ColumnData, index: number) => {
          return (
            <Column
              name={value.name}
              cellRenderer={value.dataRenderer.cellRenderer()}
              key={index}
            />
          );
        })}
      </Table>
    </TitledBlock>
  );
};
