import React from 'react';

interface OverviewContentProps {
  label: string;
  children: React.ReactNode;
}

export const OverviewContent: React.FC<OverviewContentProps> = (
  props: OverviewContentProps
) => {
  return (
    <div className={'flex mb-2'}>
      <div className={'w-56 text-gray-1 flex items-center'}>{props.label}</div>
      <div className={'flex-1'}>{props.children}</div>
    </div>
  );
};
