import React from 'react';
import { Navbar } from '../../organism/navbar/navbar';
import { Sidebar } from '../../organism/sidebar/sidebar';
import { BaseTemplateProps } from './types';

export const BaseTemplate: React.FC<BaseTemplateProps> = (
  props: BaseTemplateProps
) => {
  return (
    <div>
      <Navbar
        loading={props.loading}
        name={props.user}
        className={
          'fixed top-0 h-12 flex justify-between items-center shadow-lg'
        }
        actions={props.navbarActions}
        logoCallback={props.logoCallback}
      />
      <Sidebar
        loading={props.loading}
        deals={props.deals}
        className={'fixed top-0 left-0 pt-12 h-full w-64'}
        actions={props.sidebarActions}
      />
      <div className={'pt-12 pl-64 bg-light-gray-5 min-h-screen'}>
        <div className={'pt-16 w-full mx-auto'}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
