import React from 'react';
import { HTMLTable } from '@blueprintjs/core';
import { ParticipantTrackerRow } from './participantTrackerRow';
import { Participant } from './participant';
import { ParticipantSubheader } from './participantSubheader';
import { TitledBlock } from '../../molecules/titledBlock/titledBlock';

const Subheader: React.FC<{ text: string; padTop?: boolean }> = (props: {
  text: string;
  padTop?: boolean;
}) => {
  return (
    <ParticipantSubheader
      text={props.text}
      colSpan={4}
      padTop={props.padTop || false}
    />
  );
};

interface ParticipantTrackerBlockProps {
  participants: Participant[];
}

export const ParticipantTrackerBlock: React.FC<ParticipantTrackerBlockProps> = (
  props: ParticipantTrackerBlockProps
) => {
  let firstType: 'invited' | 'signedNDA' | 'bid' | undefined = undefined;

  const invited: JSX.Element[] = [];
  const signedNDA: JSX.Element[] = [];
  const bid: JSX.Element[] = [];

  props.participants.forEach((value: Participant, index: number) => {
    const row = <ParticipantTrackerRow participant={value} key={index} />;

    if (!value.signedNDA) {
      invited.push(row);
      firstType = 'invited';
    } else if (!value.bidsSubmitted) {
      signedNDA.push(row);
      if (firstType !== 'invited') {
        firstType = 'signedNDA';
      }
    } else {
      bid.push(row);
      if (firstType === undefined) {
        firstType = 'bid';
      }
    }
  });

  return (
    <TitledBlock
      icon={'people'}
      iconClassName={'bg-orange-1'}
      title={'Participants'}
    >
      <div className={'border-dashed border border-light-gray-1 w-full pb-2'}>
        <HTMLTable className={'w-full'}>
          <thead>
            <tr>
              <th className={'w-2/6'}>Participant</th>
              <th className={'w-1/6'}>NDA Signing Date</th>
              <th className={'w-1/6'}>Number of Bids</th>
              <th className={'w-2/6'}>Contact</th>
            </tr>
          </thead>
          <tbody>
            {invited.length !== 0 && (
              <>
                <Subheader
                  text={'Participants with Unanswered Invitations'}
                  key="invited"
                />
                {invited}
              </>
            )}
            {signedNDA.length !== 0 && (
              <>
                <Subheader
                  text={'Participants with Signed NDAs'}
                  padTop={firstType !== 'signedNDA'}
                  key="signedNDA"
                />
                {signedNDA}
              </>
            )}
            {bid.length !== 0 && (
              <>
                <Subheader
                  text={'Participants with Bids'}
                  padTop={firstType !== 'bid'}
                  key="bid"
                />
                {bid}
              </>
            )}
          </tbody>
        </HTMLTable>
      </div>
    </TitledBlock>
  );
};
