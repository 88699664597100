import React from 'react';
import {
  Card,
  Elevation,
  Menu,
  Spinner,
  NonIdealState,
} from '@blueprintjs/core';
import { Action } from '../../../types/action';
import { IconNames } from '@blueprintjs/icons';

export interface DealItem {
  name: string;
  callback: () => void;
}

interface SidebarProps {
  loading?: boolean;
  actions?: Action[];
  deals: DealItem[];
  className?: string;
}

export const Sidebar: React.FunctionComponent<SidebarProps> = (
  props: SidebarProps
) => {
  return (
    <div className={(props.className || '') + ' bp3-dark'}>
      <Card elevation={Elevation.THREE} className={'h-full rounded-none pt-3'}>
        <Menu>
          {props.actions &&
            props.actions.map((value: Action, index: number) => (
              <Menu.Item
                text={value.name}
                icon={value.icon}
                onClick={value.callback}
                disabled={value.disabled}
                intent={value.intent}
                key={'action' + index}
                className={'my-2'}
              />
            ))}
          <Menu.Divider title="Active Deals" className={'mt-6 mb-4'} />
          {(() => {
            if (props.loading) {
              return <Spinner className={'my-20'}/>;
            } else if (props.deals.length === 0) {
              return (
                <NonIdealState
                  icon={IconNames.SEARCH}
                  description={'No active deals found.'}
                />
              );
            } else {
              return props.deals.map((value: DealItem, index: number) => (
                <Menu.Item
                  text={value.name}
                  icon={'chevron-right'}
                  key={'deal' + index}
                  onClick={value.callback}
                  className={'ml-4 my-1'}
                />
              ));
            }
          })()}
        </Menu>
      </Card>
    </div>
  );
};
