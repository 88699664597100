import React from 'react';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import './arithos.css';
import { Application } from './component_refactor/pages/application/application';

import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './apollo';

export const Arithos: React.FunctionComponent = () => {
  return (
    <ApolloProvider client={client}>
      <Application />
    </ApolloProvider>
  );
};
