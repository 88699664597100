import React from 'react';
import { Icon, IconName, Colors } from '@blueprintjs/core';

interface TitledBlockIconProps {
  className?: string;
  icon: IconName;
}

export const TitledBlockIcon: React.FC<TitledBlockIconProps> = (
  props: TitledBlockIconProps
) => {
  return (
    <div className={props.className + ' flex items-center justify-center'}>
      <Icon color={Colors.WHITE} icon={props.icon} iconSize={24}/>
    </div>
  );
};
