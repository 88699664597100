import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { DataTable } from '../../molecules/datatable/datatable';
import { ArrayRenderer } from '../../molecules/datatable/dataRenderer/arrayRenderer';
import { Bid } from '../../../types/bid';
import { Action } from '../../../types/action';

interface BidBlockProps {
  bids: Bid[];
  actions?: Action[];
}

export const BidBlock: React.FC<BidBlockProps> = (props: BidBlockProps) => {
  const participants: string[] = [];
  const amounts: number[] = [];
  const prices: number[] = [];
  const types: string[] = [];
  props.bids.forEach((value: Bid) => {
    participants.push(value.participant);
    amounts.push(value.amount);
    prices.push(value.price);
    types.push(value.type);
  });

  return (
    <DataTable
      icon={IconNames.COMPARISON}
      iconClassName={'bg-red-1'}
      title={'Bids'}
      numRows={props.bids.length}
      columns={[
        {
          name: 'Participant',
          dataRenderer: new ArrayRenderer<string>(participants, ''),
        },
        {
          name: 'Volume (mm)',
          dataRenderer: new ArrayRenderer<number>(amounts, 0),
        },
        {
          name: 'Price (%)',
          dataRenderer: new ArrayRenderer<number>(prices, 0),
        },
        {
          name: 'Type',
          dataRenderer: new ArrayRenderer<string>(types, ''),
        },
      ]}
      actions={props.actions}
    />
  );
};
