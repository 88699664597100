import React, { useState } from 'react';
import { TitledBlock } from '../../molecules/titledBlock/titledBlock';
import { IconNames } from '@blueprintjs/icons';
import { OverviewContent } from './overviewContent';
import { H3 } from '@blueprintjs/core';
import { EditableOverviewInfo } from './editableOverviewInfo';

interface OverviewBlockProps {
  editable: boolean;
  sponsor: string;
  creating?: boolean;
  codename?: string;
  description?: string;
  dataroom?: string;
  dateCompleted?: string;
  raiseAmount?: number;
  targetRate?: number;
  className?: string;
}

export const OverviewBlock: React.FunctionComponent<OverviewBlockProps> = (
  props: OverviewBlockProps
) => {
  const [editing, setEditing] = useState<boolean>(false);

  return (
    <TitledBlock
      title={'Overview'}
      actions={
        props.editable && !editing
          ? [
              {
                icon: IconNames.EDIT,
                name: 'Edit',
                callback: () => {
                  setEditing(true);
                },
              },
            ]
          : undefined
      }
      icon={'id-number'}
      iconClassName={'bg-green-2'}
      className={props.className}
    >
      <div className={'flex flex-col'}>
        <div className="pb-6">
          <H3 className={'mb-4'}>
            <EditableOverviewInfo name={'Codename'} editing={editing}>
              {props.codename}
            </EditableOverviewInfo>
          </H3>
          <div>
            <EditableOverviewInfo
              name={'Description'}
              editing={editing}
              multiline={true}
            >
              {props.description}
            </EditableOverviewInfo>
          </div>
        </div>
        <OverviewContent label={'Sponsor'}>{props.sponsor}</OverviewContent>
        <OverviewContent label={'Amount'}>
          <EditableOverviewInfo name={'Amount'} editing={editing}>
            {props.raiseAmount ? String(props.raiseAmount) : undefined}
          </EditableOverviewInfo>
        </OverviewContent>
        <OverviewContent label={'Spread'}>
          <EditableOverviewInfo name={'Spread'} editing={editing}>
            {props.targetRate ? String(props.targetRate) : undefined}
          </EditableOverviewInfo>
        </OverviewContent>
        <OverviewContent label={'Dataroom'}>
          <EditableOverviewInfo name={'Dataroom'} editing={editing}>
            {props.dataroom}
          </EditableOverviewInfo>
        </OverviewContent>
        <OverviewContent label={'End Date'}>
          <EditableOverviewInfo name={'Date Completed'} editing={editing}>
            {props.dateCompleted}
          </EditableOverviewInfo>
        </OverviewContent>
      </div>
    </TitledBlock>
  );
};
