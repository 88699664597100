import { DataRenderer } from './dataRenderer';

export class ArrayRenderer<T extends any> extends DataRenderer<T> {
  private readonly data: T[];
  private readonly fallback: T | undefined;

  constructor(data: T[], fallback: T) {
    super();
    this.data = data;
    this.fallback = fallback;
  }

  getData(row: number): T | undefined {
    return this.data[row] || this.fallback;
  }
}
