import React from 'react';

interface ScrollingListItemProps {
  name: string;
  children: React.ReactNode;
}

export type ScrollingListItemType =
  | React.ReactElement<ScrollingListItemProps>
  | undefined
  | false;
export interface ScrollingListItemArrayType
  extends Array<ScrollingListItemType> {}

export const ScrollingListItem: React.FunctionComponent<
  ScrollingListItemProps
> = (props: ScrollingListItemProps) => {
  return <>{props.children}</>;
};
