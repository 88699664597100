import React from 'react';
import { Card, Elevation, H5, IconName } from '@blueprintjs/core';
import { ActionDropdown } from '../actionDropdown/actionDropdown';
import { TitledBlockIcon } from './titledBlockIcon';
import { Action } from '../../../types/action';

interface TitledBlockProps {
  icon?: IconName;
  iconClassName?: string;
  title: string;
  actions?: Action[];
  className?: string;
  children: React.ReactNode;
}

export const TitledBlock: React.FunctionComponent<TitledBlockProps> = (
  props: TitledBlockProps
) => {
  return (
    <div className={props.className}>
      <Card elevation={Elevation.TWO} className={'p-0 m-0 flex flex-col overflow-hidden'}>
        <div
          className={
            'flex justify-between items-center h-12 border-b-2 border-light-gray-3 pr-6'
          }
        >
          <div className={'flex items-center'}>
            {props.icon && (
              <TitledBlockIcon
                icon={props.icon}
                className={props.iconClassName + ' h-12 w-12'}
              />
            )}
            <H5 className={(props.icon ? 'mx-5' : 'mx-6') + ' my-0'}>
              {props.title}
            </H5>
          </div>
          {props.actions && <ActionDropdown actions={props.actions} />}
        </div>
        <div className={'p-6'}>{props.children}</div>
      </Card>
    </div>
  );
};
