import React from 'react';
import { Popover, Menu, Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Action } from '../../../types/action';

interface ActionDropdownProps {
  actions: Action[];
}

export const ActionDropdown: React.FunctionComponent<ActionDropdownProps> = (
  props: ActionDropdownProps
) => {
  return (
    <Popover
      content={
        <Menu>
          {props.actions.map((value: Action) => (
            <Menu.Item
              text={value.name}
              onClick={value.callback}
              key={value.name}
              icon={value.icon}
              disabled={value.disabled}
              intent={value.intent}
            />
          ))}
        </Menu>
      }
      position={'bottom-right'}
    >
      <Button rightIcon={IconNames.SYMBOL_TRIANGLE_DOWN} minimal={true}>
        Actions
      </Button>
    </Popover>
  );
};
