import React from 'react';
import ReactEchartsCore from 'echarts-for-react/lib/core';

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';
import { Colors } from '@blueprintjs/core';

interface ChartProps {
  yAxisName: string;
  xAxisName: string;
  height: number;
  data: [number, number][];
}

export const Chart: React.FC<ChartProps> = (props: ChartProps) => {
  const modifiedData: [number, number][] = [];
  props.data
    .sort((a: [number, number], b: [number, number]) => a[0] - b[0])
    .forEach(
      (value: [number, number], index: number, array: [number, number][]) => {
        if (index > 0) {
          const prevValue = array[index - 1];
          if (prevValue[0] !== value[0] - 1 || prevValue[0] !== value[0]) {
            modifiedData.push([value[0] - 1, prevValue[1]]);
          }
        }
        modifiedData.push(value);
      }
    );

  return (
    <ReactEchartsCore
      style={{ height: props.height + 'px', width: '100%' }}
      echarts={echarts}
      option={{
        animation: true,
        tooltip: {
          triggerOn: 'none',
          formatter: (params: any) => {
            return props.yAxisName + ': ' + params[0].value[1];
          },
        },
        xAxis: {
          name: props.xAxisName,
          nameLocation: 'middle',
          nameGap: 30,
          type: 'value',
          axisPointer: {
            value: 0,
            lineStyle: {
              color: Colors.GRAY1,
              opacity: 0.5,
              width: 2,
            },
            label: {
              show: true,
              precision: 0,
              backgroundColor: Colors.GRAY1,
            },
            handle: {
              show: true,
              color: Colors.GRAY1,
              size: 35,
            },
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          name: props.yAxisName,
          nameLocation: 'middle',
          nameGap: 30,
          type: 'value',
          splitLine: {
            show: false,
          },
          z: 10,
        },
        grid: {
          bottom: 75,
        },
        series: [
          {
            name: 'Bids',
            type: 'line',
            symbol: 'none',
            step: 'middle',
            sampling: 'average',
            itemStyle: {
              normal: {
                color: Colors.BLUE4,
              },
            },
            areaStyle: {},
            data: modifiedData,
          },
        ],
      }}
    />
  );
};
