import ApolloClient from 'apollo-boost';

export const client = new ApolloClient({
  /*uri: 'https://arithos-backend.herokuapp.com',*/
  uri:
    process.env.NODE_ENV === 'production'
      ? 'https://arithos-backend.herokuapp.com'
      : 'http://localhost:8383',
  request: operation => {
    let user: string | null = localStorage.getItem('user');
    if (user !== 'andy' && user !== 'jim' && user !== 'sam') {
      user = null;
    }

    operation.setContext({
      headers: {
        authorization: 'Bearer ' + (user ? user : 'andy'),
      },
    });
  },
});
