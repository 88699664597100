import React from 'react';
import { ScrollingList } from '../../organism/scrollingList/scrollingList';
import { ScrollingListItem } from '../../organism/scrollingList/scrollingListItem';
import { OverviewBlock } from '../../organism/overviewBlock/overviewBlock';
import { ChartBlock } from '../../organism/chartBlock/chartBlock';
import {
  AllocationBlock,
  Allocation,
} from '../../organism/allocationBlock/allocationBlock';
import { BidBlock } from '../../organism/bidBlock/bidBlock';
import { ParticipantTrackerBlock } from '../../organism/participantTracker/participantTrackerBlock';
import { Participant } from '../../organism/participantTracker/participant';
import { Bid } from '../../../types/bid';
import { Action } from '../../../types/action';

interface AllocationProps {
  allocations: Allocation[];
}

interface ParticipantProps {
  participants: Participant[];
}

interface DealTemplateProps {
  isAdmin: boolean;
  overview: {
    codename: string;
    sponsor: string;
    dataroom: string;
    description: string;
    raiseAmount: number;
    targetRate: number;
    dateCompleted: string;
  };
  demandCurve: {
    xAxisName: string;
    yAxisName: string;
    data: [number, number][];
  };
  allocation?: AllocationProps;
  bids: {
    bids: Bid[];
    actions: Action[];
  };
  participants?: ParticipantProps;
}

export const DealTemplate: React.FC<DealTemplateProps> = (
  props: DealTemplateProps
) => {
  return (
    <div className={'flex flex-col w-full items-center'}>
      <ScrollingList
        className={'w-5/6'}
        topOffset={'top-16'}
        itemOffset={'pt-16 -mt-16'}
      >
        <ScrollingListItem name="Overview">
          <OverviewBlock editable={false} {...props.overview} />
        </ScrollingListItem>
        <ScrollingListItem name={'Demand Curve'}>
          <ChartBlock {...props.demandCurve} />
        </ScrollingListItem>
        {props.isAdmin && props.allocation && (
          <ScrollingListItem name={'Current Allocation'}>
            <AllocationBlock {...props.allocation} />
          </ScrollingListItem>
        )}
        <ScrollingListItem name={'Bids'}>
          <BidBlock {...props.bids} />
        </ScrollingListItem>
        {props.isAdmin && props.participants && (
          <ScrollingListItem name={'Participants'}>
            <ParticipantTrackerBlock {...props.participants} />
          </ScrollingListItem>
        )}
      </ScrollingList>
    </div>
  );
};
