// THIS IS HACKY - MAKE SURE TO FIX THIS LATER

import React from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { InputGroup } from '../../atoms/blueprintWrappers/InputGroup';
import { Button, Dialog, Callout } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { CreateBidFormInfo } from './types';
import { CreateBidFormElement } from './createBidFormElement';
import { NumericInput } from '../../atoms/blueprintWrappers/NumericInput';

interface CreateDealDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: CreateBidFormInfo) => void;
}

export const CreateBidDialog: React.FC<CreateDealDialogProps> = (
  props: CreateDealDialogProps
) => {
  return (
    <Dialog
      title={'Create Bid'}
      icon={IconNames.SEND_TO_GRAPH}
      onClose={props.onClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      isCloseButtonShown={true}
      isOpen={props.isOpen}
    >
      <div className="mx-6 mt-8 mb-2">
        <Callout intent="warning" title="Work In Progress">
          {'This dialog is a temporary element to allow bid creation.'}
        </Callout>
        <Formik<CreateBidFormInfo>
          initialValues={{
            amount: 0,
            price: 0,
          }}
          validate={(values: CreateBidFormInfo) => {
            const errors: FormikErrors<CreateBidFormInfo> = {};

            if (values.amount === 0 || !Number.isInteger(values.amount)) {
              errors.amount = 'Amount must be a positive integer.';
            }
            if (values.price === 0) {
              errors.price = 'Price must be a positive number.';
            }

            return errors;
          }}
          onSubmit={props.onSubmit}
        >
          {({ errors, touched }) => {
            return (
              <Form>
                <div className={'flex mt-8'}>
                  <CreateBidFormElement
                    label={'Volume (mm)'}
                    labelInfo={'(required)'}
                    name={'amount'}
                    component={NumericInput}
                    placeholder={'100'}
                    errors={errors}
                    touched={touched}
                    className={'flex-1 mr-2 mb-5'}
                    fill={true}
                    leftIcon={IconNames.DOLLAR}
                  />
                  <CreateBidFormElement
                    label={'Price (%)'}
                    labelInfo={'(required)'}
                    name={'price'}
                    component={NumericInput}
                    placeholder={'100'}
                    errors={errors}
                    touched={touched}
                    className={'flex-1 ml-2 mb-5'}
                    fill={true}
                    leftIcon={IconNames.PERCENTAGE}
                  />
                </div>
                <div className={'flex justify-end mt-10'}>
                  <Button
                    type={'reset'}
                    text={'Cancel'}
                    onClick={props.onClose}
                  />
                  <Button
                    type={'submit'}
                    text={'Create Bid'}
                    intent={'primary'}
                    className={'ml-2'}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
};
