import gql from 'graphql-tag';

export const GET_USER_AND_DEALS = gql`
  {
    currentUser {
      id
      participating {
        id
        deal {
          id
          codename
          amount
          spread
          endDate
          description
          sponsor {
            id
            user {
              id
              firm
            }
          }
        }
      }
    }
  }
`;

export interface ContentUser {
  currentUser: {
    id: string;
    participating: {
      id: string;
      deal: {
        id: string;
        codename: string;
        amount: number;
        spread: number;
        endDate: string;
        description: string;
        sponsor: {
          id: string;
          user: {
            id: string;
            firm: string;
          };
        };
      };
    }[];
  };
}
