import React, { useState, useEffect } from 'react';
import { NavTab } from '../../molecules/navTab/navTab';
import {
  ScrollingListItemType,
  ScrollingListItemArrayType,
} from './scrollingListItem';
import { Card, Elevation } from '@blueprintjs/core';

type ScrollingListChildrenType =
  | ScrollingListItemType
  | ScrollingListItemArrayType;

interface ScrollingListProps {
  className?: string;
  topOffset?: string;
  bottomOffset?: string;
  itemOffset?: string;
  children: ScrollingListChildrenType;
}

export const ScrollingList: React.FC<ScrollingListProps> = (
  props: ScrollingListProps
) => {
  const [currentSection, setCurrentSection] = useState<number>(0);

  // Sets the mounted state
  const [mounted, setMounted] = useState<boolean>(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const refs: (HTMLDivElement | null)[] = [];

  const names: string[] = [];
  const output: JSX.Element[] = [];

  const SetDivRef = (ref: HTMLDivElement | null) => {
    refs.push(ref);
  };

  const children = React.Children.toArray(props.children).filter(value => {
    return value;
  });
  children.forEach((child: ScrollingListItemType, index: number) => {
    if (!child) return;
    names.push(child.props.name);
    output.push(
      <div
        key={index}
        ref={SetDivRef}
        className={
          (props.itemOffset ? props.itemOffset : '') +
          (index === children.length - 1 ? ' h-screen' : '')
        }
      >
        <div className={index !== children.length - 1 ? 'mb-8' : ''}>
          {child.props.children}
        </div>
      </div>
    );
  });

  useEffect(() => {
    window.addEventListener('scroll', () => {
      let ref: HTMLDivElement | null;
      let bestIndex: number | undefined = undefined;
      let closestBottom: number = Number.MAX_VALUE;
      for (let index = 0; index < refs.length; index++) {
        ref = refs[index];
        if (!ref) continue;
        const bottom = ref.getBoundingClientRect().bottom;
        if (bottom > 100 && bottom < closestBottom) {
          bestIndex = index;
          closestBottom = bottom;
        }
      }

      if (bestIndex !== undefined) setCurrentSection(bestIndex);
    });
  }, [refs]);

  return (
    <div className={props.className}>
      <div className={'flex'}>
        <div className={'inline-block w-full'}>{output}</div>
        {mounted && (
          <div
            className={
              'sticky inline-block h-full ' +
              (props.topOffset ? props.topOffset : '')
            }
          >
            <Card className={'sticky ml-4'} elevation={Elevation.TWO}>
              <NavTab
                tabs={names}
                selectedTabIndex={currentSection}
                onTabChange={(newTabId: number) => {
                  if (newTabId >= refs.length) return;
                  if (refs[newTabId]) {
                    refs[newTabId]!.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};
