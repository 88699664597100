import React from 'react';
import { EditableText } from '@blueprintjs/core';

interface EditableOverviewInfoProps {
  name: string;
  editing: boolean;
  multiline?: boolean;
  children: React.ReactElement<{ children: string }> | string | undefined;
}

export const EditableOverviewInfo: React.FC<EditableOverviewInfoProps> = (
  props: EditableOverviewInfoProps
) => {
  let text: string | undefined = undefined;
  if (typeof props.children === 'string') {
    text = props.children;
  } else if (props.children === undefined) {
    text = undefined;
  } else {
    text = props.children.props.children;
  }

  return (
    <>
      {props.editing && (
        <div
          className={
            'border-dashed border-2 border-gray-5 p-1' +
            (props.multiline ? '' : ' inline-block')
          }
        >
          <EditableText
            placeholder={'Click to Add ' + props.name}
            defaultValue={text}
            multiline={props.multiline}
          />
        </div>
      )}
      {!props.editing && props.children}
    </>
  );
};
