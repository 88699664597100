import React from 'react';
import { Tabs, TabId, Tab } from '@blueprintjs/core';

interface NavTabProps {
  tabs: string[];
  selectedTabIndex: number;
  onTabChange: (newTabId: number) => void;
  className?: string;
}

export const NavTab: React.FunctionComponent<NavTabProps> = (
  props: NavTabProps
) => {
  if (props.tabs.length === 0) return null;

  return (
    <Tabs
      id="navigation"
      onChange={(newTabId: TabId) => {
        if (typeof newTabId === 'string') {
          console.log(new Error('Passed string to navigation tabs'));
          return;
        }
        props.onTabChange(newTabId);
      }}
      selectedTabId={props.selectedTabIndex}
      vertical={true}
      className={props.className}
    >
      {props.tabs.map((value: string, index: number) => (
        <Tab id={index} title={value} key={index} />
      ))}
    </Tabs>
  );
};
