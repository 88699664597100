import React from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { InputGroup } from '../../atoms/blueprintWrappers/InputGroup';
import { Button, Dialog, Divider } from '@blueprintjs/core';
import { TextArea } from '../../atoms/blueprintWrappers/TextArea';
import { CreateDealFormElement } from './createDealFormElement';
import { FormInfo } from './types';
import { IconNames } from '@blueprintjs/icons';
import { NumericInput } from '../../atoms/blueprintWrappers/NumericInput';
import { DateInput } from '../../atoms/blueprintWrappers/DateInput';
import { IDateFormatProps } from '@blueprintjs/datetime';
import moment from 'moment';

function getMomentFormatter(format: string): IDateFormatProps {
  return {
    formatDate: date => moment(date).format(format),
    parseDate: str => moment(str, format).toDate(),
  };
}

interface CreateDealDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormInfo) => void;
}

export const CreateDealDialog: React.FC<CreateDealDialogProps> = (
  props: CreateDealDialogProps
) => {
  return (
    <Dialog
      title={'Create Deal'}
      icon={IconNames.ADD}
      onClose={props.onClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      isCloseButtonShown={true}
      isOpen={props.isOpen}
    >
      <div className="mx-6 mt-8 mb-2">
        <Formik<FormInfo>
          initialValues={{
            codename: '',
            description: '',
            dataroom: '',
            amount: 0,
            rate: 0,
            endDate: null,
          }}
          validate={(values: FormInfo) => {
            const errors: FormikErrors<FormInfo> = {};

            if (values.codename === '') {
              errors.codename = 'Codename is a required field.';
            }

            if (values.amount <= 0 || !Number.isInteger(values.amount)) {
              errors.amount = 'Amount must be a positive integer.';
            }

            if (values.rate <= 0 || !Number.isInteger(values.amount)) {
              errors.rate = 'Rate must be a positive integer.';
            }

            if (
              values.endDate === null ||
              moment(values.endDate).isSameOrBefore(moment(), 'minute')
            ) {
              errors.endDate = 'The end date and time must be in the future.';
            }

            return errors
          }}
          onSubmit={props.onSubmit}
        >
          {({ errors, touched }) => {
            return (
              <Form>
                <CreateDealFormElement
                  label={'Codename'}
                  labelInfo={'(required)'}
                  name={'codename'}
                  component={InputGroup}
                  placeholder={'Project Lighthouse'}
                  errors={errors}
                  touched={touched}
                  className={'w-3/4 mb-5'}
                />
                <div className={'flex'}>
                  <CreateDealFormElement
                    label={'Amount (MM)'}
                    labelInfo={'(required)'}
                    name={'amount'}
                    component={NumericInput}
                    placeholder={'100'}
                    errors={errors}
                    touched={touched}
                    className={'flex-1 mr-2 mb-5'}
                    fill={true}
                  />
                  <CreateDealFormElement
                    label={'Rate (Bps over LIBOR)'}
                    labelInfo={'(required)'}
                    name={'rate'}
                    component={NumericInput}
                    placeholder={'50'}
                    errors={errors}
                    touched={touched}
                    className={'flex-1 ml-2 mb-5'}
                    fill={true}
                  />
                </div>
                <CreateDealFormElement
                  label={'End Date'}
                  labelInfo={'(required)'}
                  name={'endDate'}
                  component={DateInput}
                  placeholder={'Select Date'}
                  errors={errors}
                  touched={touched}
                  timePickerProps={{
                    useAmPm: true,
                  }}
                  closeOnSelection={false}
                  {...getMomentFormatter('LT [at] L')}
                />
                <Divider className="mt-6 mb-8" />
                <CreateDealFormElement
                  label={'Description'}
                  name={'description'}
                  component={TextArea}
                  placeholder={'Enter description here'}
                  errors={errors}
                  touched={touched}
                  className={'mb-6'}
                  growVertically
                  fill
                />
                <CreateDealFormElement
                  label={'Dataroom'}
                  name={'dataroom'}
                  component={InputGroup}
                  placeholder={'Enter link to dataroom'}
                  errors={errors}
                  touched={touched}
                  className={'w-3/4'}
                />
                <div className={'flex justify-end mt-10'}>
                  <Button
                    type={'reset'}
                    text={'Cancel'}
                    onClick={props.onClose}
                  />
                  <Button
                    type={'submit'}
                    text={'Create Deal'}
                    intent={'primary'}
                    className={'ml-2'}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
};
