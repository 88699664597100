import React from 'react';
import { FormGroup } from '@blueprintjs/core';
import { FormikErrors, FormikTouched, Field } from 'formik';
import { CreateBidFormInfo as FormInfo } from './types';

type FormFields = keyof FormInfo;
interface CreateDealFormElementProps {
  errors: FormikErrors<FormInfo>;
  touched: FormikTouched<FormInfo>;
  name: FormFields;
  label: string;
  labelInfo?: string;
  className?: string;
  component: React.ComponentType<any>;
}

type PropType = CreateDealFormElementProps & { [index: string]: any };

export const CreateBidFormElement: React.FC<PropType> = (props: PropType) => {
  const {
    errors,
    touched,
    name,
    label,
    labelInfo,
    className,
    component,
    ...restProps
  } = props;

  const hasError = (name: FormFields) => {
    return errors[name] && touched[name];
  };

  const helperText = hasError(name) ? errors[name] : undefined;
  const intent = hasError(name) ? 'danger' : undefined;

  return (
    <FormGroup
      label={label}
      labelInfo={labelInfo}
      className={className}
      helperText={helperText}
      intent={intent}
    >
      <Field
        name={name}
        label={props.label}
        component={props.component}
        intent={intent}
        {...restProps}
      />
    </FormGroup>
  );
};
