import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { DataTable } from '../../molecules/datatable/datatable';
import { ArrayRenderer } from '../../molecules/datatable/dataRenderer/arrayRenderer';

export interface Allocation {
  firm: string;
  amount: number;
}

interface AllocationBlockProps {
  allocations: Allocation[];
}

export const AllocationBlock: React.FC<AllocationBlockProps> = (
  props: AllocationBlockProps
) => {
  const participants: string[] = [];
  const amounts: number[] = [];
  props.allocations.forEach((value: Allocation) => {
    participants.push(value.firm);
    amounts.push(value.amount);
  });

  return (
    <DataTable
      icon={IconNames.LAYOUT_HIERARCHY}
      iconClassName={'bg-green-3'}
      title={'Current Allocations'}
      numRows={props.allocations.length}
      columns={[
        {
          name: 'Participants',
          dataRenderer: new ArrayRenderer<string>(participants, ''),
        },
        {
          name: 'Allocation',
          dataRenderer: new ArrayRenderer(amounts, 0),
        },
      ]}
    />
  );
};
