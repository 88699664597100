// THIS IS HACKY - MAKE SURE TO FIX THIS LATER

import React from 'react';
import { Formik, Form, FormikErrors } from 'formik';
import { InputGroup } from '../../atoms/blueprintWrappers/InputGroup';
import { Button, Dialog, Callout } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { JoinDealFormInfo } from './types';
import { JoinDealFormElement } from './joinDealFormElement';

interface CreateDealDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: JoinDealFormInfo) => void;
}

export const JoinDealDialog: React.FC<CreateDealDialogProps> = (
  props: CreateDealDialogProps
) => {
  return (
    <Dialog
      title={'Join Deal'}
      icon={IconNames.SEND_TO_GRAPH}
      onClose={props.onClose}
      canEscapeKeyClose={false}
      canOutsideClickClose={false}
      isCloseButtonShown={true}
      isOpen={props.isOpen}
    >
      <div className="mx-6 mt-8 mb-2">
        <Callout intent="warning" title="Work In Progress">
          {'This dialog is a temporary element to allow deal joining.'}
        </Callout>
        <Formik<JoinDealFormInfo>
          initialValues={{
            dealId: '',
          }}
          validate={(values: JoinDealFormInfo) => {
            const errors: FormikErrors<JoinDealFormInfo> = {};

            if (values.dealId === '') {
              errors.dealId = 'Deal ID is a required field.';
            }

            return errors;
          }}
          onSubmit={props.onSubmit}
        >
          {({ errors, touched }) => {
            return (
              <Form>
                <JoinDealFormElement
                  label={'Deal ID'}
                  labelInfo={'(required)'}
                  name={'dealId'}
                  component={InputGroup}
                  placeholder={'asdf1234'}
                  errors={errors}
                  touched={touched}
                  className={'mt-8'}
                />
                <div className={'flex justify-end mt-10'}>
                  <Button
                    type={'reset'}
                    text={'Cancel'}
                    onClick={props.onClose}
                  />
                  <Button
                    type={'submit'}
                    text={'Join Deal'}
                    intent={'primary'}
                    className={'ml-2'}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Dialog>
  );
};
