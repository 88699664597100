import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { DealTemplate } from '../../../template/dealTemplate/dealTemplate';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { GetDealResult, GET_DEAL, GetDealVariables } from './query';
import { ApplicationToaster } from '../toaster';
import moment from 'moment';
import { Bid } from '../../../../types/bid';
import { Allocation } from '../../../organism/allocationBlock/allocationBlock';
import { Participant } from '../../../organism/participantTracker/participant';
import { NavigationUser, GET_USER_NAVIGATION } from '../navigation/query';
import { IconNames } from '@blueprintjs/icons';
import { AddBidResult, AddBidVariables, ADD_BID } from './mutation';
import { CreateBidDialog } from '../../../organism/createBidDialog/createBidDialog';

interface URLProps {
  dealId?: string;
}

export const DealContent: React.FC<RouteComponentProps & URLProps> = props => {
  const [isCreatingBid, setIsCreatingBid] = useState<boolean>(false);
  const { loading, error, data, refetch } = useQuery<
    GetDealResult,
    GetDealVariables
  >(GET_DEAL, { variables: { dealId: props.dealId! } });
  // TODO THIS IS HACKY LOL FIX THIS.
  // Probably need a general refactor of graphql stuff accordign ot best practices
  const { error: error2, data: data2 } = useQuery<NavigationUser>(
    GET_USER_NAVIGATION
  );
  const [addBid] = useMutation<AddBidResult, AddBidVariables>(ADD_BID);

  if (error) {
    ApplicationToaster.show({ message: error.message, intent: 'danger' });
  }

  if (error2) {
    ApplicationToaster.show({ message: error2.message, intent: 'danger' });
  }

  return data && data2 ? (
    <>
      <CreateBidDialog
        isOpen={isCreatingBid}
        onClose={() => {
          setIsCreatingBid(false);
        }}
        onSubmit={async value => {
          try {
            const result = await addBid({
              variables: {
                dealId: data.getDeal.id,
                amount: value.amount,
                price: value.price,
              },
            });

            if (result.errors) {
              result.errors.forEach(values => {
                ApplicationToaster.show({ message: values.message });
              });
            }
          } catch (e) {
            ApplicationToaster.show({ message: String(e), intent: 'danger' });
          }

          refetch();
          setIsCreatingBid(false);
        }}
      />
      <DealTemplate
        isAdmin={data.getDeal.sponsor.user.id === data2.currentUser.id}
        overview={{
          codename: data.getDeal.codename,
          description: data.getDeal.description,
          sponsor: data.getDeal.sponsor.user.firm,
          raiseAmount: data.getDeal.amount,
          targetRate: data.getDeal.spread,
          dataroom: data.getDeal.dataroom,
          dateCompleted: moment(data.getDeal.endDate).format('L LT'),
        }}
        demandCurve={{
          xAxisName: 'Volume (mm)',
          yAxisName: 'Price (%)',
          data: data.getDeal.auctionResults.points.map(value => [
            value.amount,
            value.price,
          ]),
        }}
        bids={{
          bids: (() => {
            const bids: Bid[] = [];
            for (const bidder of data.getDeal.bidders) {
              for (const bid of bidder.bids) {
                bids.push({
                  participant: bidder.user.firm,
                  amount: bid.amount,
                  price: bid.price,
                  type: bid.type,
                });
              }
            }
            return bids;
          })(),
          actions: [
            {
              name: 'Add Bid',
              icon: IconNames.ADD,
              callback: () => {
                setIsCreatingBid(true);
              },
            },
          ],
        }}
        allocation={{
          allocations: data.getDeal.auctionResults.allocations.map(
            (value): Allocation => {
              return {
                amount: value.amount,
                firm: value.participant.user.firm,
              };
            }
          ),
        }}
        participants={{
          participants: data.getDeal.bidders.map(
            (value): Participant => ({
              firm: value.user.firm,
              contact: value.user.firstName + ' ' + value.user.lastName,
              email: value.user.email,
              phone: value.user.phone,
              signedNDA:
                value.ndaDate && moment(value.ndaDate).isValid()
                  ? moment(value.ndaDate).format('L')
                  : undefined,
              bidsSubmitted: value.bids.filter(bid => bid.type === 'PRICE')
                .length,
            })
          ),
        }}
      />
    </>
  ) : (
    <div />
  );
};
