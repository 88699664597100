import React from 'react';
import { ActiveDealsBlock } from '../../organism/activeDealsBlock/activeDealsBlock';
import { ActiveDeal } from '../../organism/activeDealsBlock/activeDeal';

interface MainTemplateProps {
  loading?: boolean;
  activeDeals: ActiveDeal[];
}

export const MainTemplate: React.FC<MainTemplateProps> = (
  props: MainTemplateProps
) => {
  return (
    <div className="flex flex-col items-center">
      <ActiveDealsBlock
        deals={props.activeDeals}
        loading={props.loading}
        className={'w-3/4'}
      />
    </div>
  );
};
