import gql from 'graphql-tag';

export interface GetDealVariables {
  dealId: string;
}

export const GET_DEAL = gql`
  query getDeal($dealId: ID!) {
    getDeal(dealId: $dealId) {
      id
      codename
      description
      amount
      spread
      dataroom
      endDate
      sponsor {
        id
        user {
          id
          firm
        }
      }
      bidders {
        id
        ndaDate
        user {
          id
          firm
          firstName
          lastName
          email
          phone
        }
        bids {
          id
          price
          amount
          type
          date
        }
      }
      auctionResults {
        points {
          amount
          price
        }
        allocations {
          participant {
            id
            user {
              id
              firm
            }
          }
          amount
          price
        }
      }
    }
  }
`;

export interface GetDealResult {
  getDeal: {
    id: string;
    codename: string;
    description: string;
    amount: number;
    spread: number;
    dataroom: string;
    endDate: string;
    sponsor: {
      id: string;
      user: {
        id: string;
        firm: string;
      };
    };
    bidders: {
      id: string;
      ndaDate: string;
      user: {
        id: string;
        firm: string;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
      };
      bids: {
        id: string;
        price: number;
        amount: number;
        type: string;
        date: string;
      }[];
    }[];
    auctionResults: {
      points: {
        amount: number;
        price: number;
      }[];
      allocations: {
        participant: {
          id: string;
          user: {
            id: string;
            firm: string;
          };
        };
        amount: number;
        price: number;
      }[];
    };
  };
}
