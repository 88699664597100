import React from 'react';
import { ActiveDeal } from './activeDeal';

interface ActiveDealsRowProps {
  deal: ActiveDeal;
}

// No Tailwin b/c of CSS specificity
const alignMiddle = { verticalAlign: 'middle' };

export const ActiveDealRow: React.FC<ActiveDealsRowProps> = (
  props: ActiveDealsRowProps
) => {
  return (
    <tr onClick={props.deal.callback}>
      <td className={'w-1/2'}>
        <div className={'flex flex-col'}>
          <div className={'my-2 font-semibold text-dark-gray-3'}>
            {props.deal.codename}
          </div>
          <div className={'mb-4 font-medium text-gray-1'}>
            {props.deal.sponsor}
          </div>
          {props.deal.description ? (
            <div className={'w-5/6'}>{props.deal.description}</div>
          ) : (
            <div className={'w-5/6 text-gray-3'}>{'This deal has no description.'}</div>
          )}
        </div>
      </td>
      <td style={alignMiddle} className={'w-1/6'}>
        <div>{props.deal.amount}</div>
      </td>
      <td style={alignMiddle} className={'w-1/6'}>
        <div>{props.deal.rate}</div>
      </td>
      <td style={alignMiddle} className={'w-1/6'}>
        <div>{props.deal.endDate}</div>
      </td>
    </tr>
  );
};
