import React from 'react';
import { FieldTypes } from './types';

import {
  IDateInputProps,
  DateInput as BlueprintDateInput,
} from '@blueprintjs/datetime';
import { Intent } from '@blueprintjs/core';

type PropTypes = FieldTypes & IDateInputProps & { intent: Intent };

export const DateInput: React.FC<PropTypes> = ({
  field: { onChange, ...restField },
  form,
  inputProps,
  popoverProps,
  ...props
}: PropTypes): React.ReactElement => {
  const { intent, ...restInputProps } = inputProps || {};
  const { onClosing, ...restPopoverProps } = popoverProps || {};
  const handlePopoverClose = (node: HTMLElement) => {
    onClosing && onClosing(node);
    form.setFieldTouched(restField.name, true);
  };

  const { onChange: onDateChange, ...restProps } = props || {
    onChange: undefined,
  };
  const handleDateChange = (selectedDate: Date, isUserChange: boolean) => {
    onDateChange && onDateChange(selectedDate, isUserChange);
    form.setFieldValue(restField.name, selectedDate);
    form.setFieldTouched(restField.name, true);
  };

  return (
    <BlueprintDateInput
      {...restField}
      {...restProps}
      onChange={handleDateChange}
      popoverProps={{
        onClosing: handlePopoverClose,
        ...restPopoverProps,
      }}
      inputProps={{
        ...restInputProps,
        intent: props.intent,
      }}
    />
  );
};
