import gql from 'graphql-tag';

export const ADD_BID = gql`
  mutation CreateBid($dealId: ID!, $price: Float!, $amount: Int!) {
    createBid(dealId: $dealId, price: $price, amount: $amount, type: PRICE) {
      id
    }
  }
`;

export interface AddBidResult {
  id: string;
}

export interface AddBidVariables {
  dealId: string;
  price: number;
  amount: number;
}
